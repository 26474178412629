@import "../../../variables.scss";

.custom-container {
  padding: 50px 50px;
  max-width: 2500px;
  margin: 0 auto;
  &.minimized {
    max-width: 1800px;
  }
  @media (max-width: $breakpoint) {
    padding: 15px 15px;
  }
}
