$superblack: #000000;
$black: #1b1919;
$white: #ffffff;
$blue: rgb(21, 44, 87);
$lightblue: rgb(136, 149, 170);
$gray: rgb(102, 102, 102);
$dark-red: #b81434;
$red: #db4949;
$green: #21b692;

$bg: rgb(21, 21, 21);

$color-main: $red;

$small-size: 18px;
$regular-size: 22px;
$big-size: 34px;
$headline-size: 55px;

$default-border-radius: 15px;

$default-box-shadow: 0px 5px 8px -1px rgba(21, 44, 87, 0.39);
// $default-box-shadow: 0 0 24px #ddd;

$default-zoom-in-scale: scale(1.005);

$default-transition-speed: 0.2s;

$breakpoint: 992px;
