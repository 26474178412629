@import "../../../variables.scss";

.hero {
  height: 100vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  .separator-wrapper {
    position: absolute;
    bottom: -12px;
  }
  @media (max-width: $breakpoint) {
    height: 200px;
  }
}
