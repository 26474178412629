@import "./variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "react-toastify/dist/ReactToastify.css";

@font-face {
  font-display: swap;
  font-family: "lightfont";
  src: url("./assets/font/ITCAvantGardeStd-light.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "boldfont";
  src: url("./assets/font/ITCAvantGardeStd-bold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "regularfont";
  src: url("./assets/font/ITCAvantGardeStd-md.woff") format("woff");
}

* {
  padding: 0px;
  margin: 0px;
  font-family: "regularfont", sans-serif;
  color: $white;
}

body,
html {
  margin: 0;
  padding: 0;
  background-color: $bg;
}