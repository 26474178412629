@import "../../../variables.scss";

.who-we-are-characteristics {
  margin: 75px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  .item {
    min-width: 25%;
    max-width: 300px;
    flex: 1 1 0px;
    text-align: center;
    .img {
      img {
        max-height: 100px;
      }
    }
    .headline {
      font-size: 30px;
      font-weight: bold;
      font-family: "boldfont";
      margin: 20px 0px;
    }
  }
  @media (max-width: $breakpoint) {
    display: block;
    .item {
      width: 100%;
      max-width: 100%;
      margin-bottom: 50px;
    }
  }
}
