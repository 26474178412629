@import "../../../variables.scss";

.anchorworkshop-bulletpoint {
  min-width: 30%;
  flex: 1 1 0px;
  .headline {
    font-family: "boldfont";
    font-weight: bold;
    font-size: 22px;
  }
  @media (max-width: $breakpoint) {
    margin-bottom: 25px;
  }
}
