@import "../../../variables.scss";

.anchorworkshop-hex-section {
  margin: 75px 0px;
  .line {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 50px;
    .item {
      img {
        height: 200px;
      }
    }
  }
  @media (max-width: $breakpoint) {
    margin: 50px 0px;
    .line {
      display: block;
      margin-top: 0px;
      .item {
        img {
          width: 100%;
          height: auto;
          margin-bottom: 25px;
        }
      }
    }
  }
}
