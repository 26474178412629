@import '../../variables.scss';

#businessnavigator {
  .filters {
    margin-top: 50px;
    display: flex;
    gap: 20px;
    justify-content: center;
    .filter {
      width: 200px;
      background-color: $gray;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding: 10px 5px;
      border-radius: $default-border-radius;
      font-family: 'boldfont';
      font-weight: bold;
      font-size: 15px;
      cursor: pointer;
    }
  }
  .reset {
    cursor: pointer;
    width: 150px;
    background-color: $gray;
    display: flex;
    justify-content: center;
    padding: 10px 5px;
    border-radius: $default-border-radius;
    font-size: 15px;
    margin: 0px auto;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    margin-top: 50px;
    .item {
      min-width: 40%;
      max-width: calc(50% - 25px);
      flex: 1 1 0px;
      .img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .headline {
        margin-top: 20px;
        font-size: $big-size;
        font-weight: bold;
        font-family: 'boldfont';
        line-height: 40px;
      }
      .subheadline {
        line-height: 30px;
        font-size: 28px;
      }
      .text {
        margin-top: 20px;
      }
      .cta {
        background-color: $gray;
        border-radius: $default-border-radius;
        padding: 10px 10px 5px;
        color: $white;
        text-decoration: none;
        margin-top: 20px;
        display: inline-block;
      }
    }
  }
  @media (max-width: $breakpoint) {
    .filters {
      display: block;
      text-align: center;
      .filter {
        display: inline-block;
        margin-bottom: 10px;
        width: 100%;
      }
    }
    .items {
      display: block;
      .item {
        margin-bottom: 50px;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
