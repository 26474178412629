@import '../../../variables.scss';

.get-to-know {
  display: flex;
  margin: 30px 50px;
  gap: 30px;
  .section {
    text-decoration: none;
    .content {
      background-color: $gray;
      padding: 30px 25px;
      border-radius: $default-border-radius;
      .header {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        .headline {
          width: 70%;
          font-family: 'boldfont';
          font-weight: bold;
          font-size: 28px;
          line-height: 32px;
        }
        .img {
          width: 30%;
          text-align: right;
          img {
            height: 75px;
          }
        }
      }
      .text {
        margin-top: 30px;
        font-size: 28px;
        line-height: 32px;
      }
    }
    .cta {
      margin-top: 20px;
      background-color: $gray;
      text-align: center;
      font-size: 28px;
      line-height: 32px;
      border-radius: $default-border-radius;
      padding: 10px 15px 2px;
    }
  }
  @media (max-width: $breakpoint) {
    display: block;
    margin: 25px 0px;
    .section {
      margin-bottom: 50px;
      .content {
        .text {
          font-size: 24px;
          line-height: 28px;
        }
      }
      .cta {
        font-size: 24px;
        line-height: 28px;
        margin: 15px 0px;
      }
    }
  }
}
