@import "../../../variables.scss";

.who-we-are-slider {
  margin: 50px 0px;
  .content {
    margin: 50px 0px;
    .person,
    .position {
      font-weight: bold;
      font-family: "boldfont";
    }
    .person {
      font-size: 25px;
    }
    .position,
    .text {
      font-size: 23px;
    }
    .text {
      margin-top: 15px;
    }
  }
  .toggle {
    text-align: center;
    cursor: pointer;
    img {
      height: 50px;
    }
  }
}
