@import '../../../variables.scss';

.what-we-do-quick-section {
  display: flex;
  gap: 75px;
  margin-top: 50px;
  margin-bottom: 150px;
  .item {
    flex: 1 1 0px;
    min-width: 30%;
    text-align: center;
    .img {
      img {
        height: 125px;
      }
    }
    .headline {
      font-weight: bold;
      font-family: 'boldfont';
      font-size: 35px;
      margin: 25px auto;
      max-width: 80%;
    }
    .description {
      font-size: 18px;
      min-height: 250px;
    }
    .cta {
      cursor: pointer;
      display: inline-block;
      background-color: $gray;
      font-size: 25px;
      line-height: 25px;
      color: $white;
      text-decoration: none;
      border-radius: $default-border-radius;
      padding: 10px 30px 5px;
    }
  }
  @media (max-width: $breakpoint) {
    display: block;
    .item {
      margin-top: 50px;
    }
  }
}
