@import "../../../variables.scss";

.custom-separator {
  text-align: center;
  img {
    width: 30%;
  }
  @media (max-width: $breakpoint) {
    img {
      width: 100%;
    }
  }
}
