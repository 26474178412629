@import '../../variables.scss';

#home {
  .bold {
    font-family: "boldfont";
    font-weight: bold;
  }
  .hero {
    height: 100vh;
    background-size: cover;
    background-position: center center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .main-logo {
      img {
        height: 300px;
      }
    }
    .trusted {
      position: absolute;
      bottom: 30px;
      text-align: center;
      width: 100%;
      .label {
        display: inline-block;
        padding: 5px 10px 1px 10px;
        background-color: $gray;
        color: $white;
        border-radius: 5px;
        margin-bottom: 20px;
      }
      .logos {
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        .logo {
          padding: 0px 20px;
          img {
            max-height: 100px;
            max-width: 150px;
          }
        }
      }
    }
    .separator-wrapper {
      position: absolute;
      bottom: -12px;
    }
  }

  .section-sympol-wrapper {
    text-align: center;
    margin: 75px;
    img {
      height: 75px;
    }
  }
  .split {
    display: flex;
    gap: 50px;
    align-items: center;
    margin: 50px 0px;
    .item {
      flex: 1 1 0px;
      min-width: 40%;
      flex-basis: content;
      .text {
        font-size: 30px;
      }
      &.img {
        text-align: center;
        img {
          max-height: 400px;
        }
      }
      .branches {
        margin-top: 20px;
        .branch {
          display: flex;
          align-content: center;
          font-size: 28px;
          a {
            color: $white;
            text-decoration: none;
            text-transform: uppercase;
            padding: 8px 10px 0px;
          }
          &.selected {
            a {
              color: $black;
              background-color: $white;
              border-radius: 15px;
            }
          }
        }
      }
      .selected-branch {
        .thumbnail {
          img {
            width: 100%;
            border-radius: 15px;
          }
        }
        .characteristics {
          display: flex;
          gap: 15px;
          flex-wrap: wrap;
          margin-top: 25px;
          .char {
            flex: 1 1 0px;
            min-width: 40%;
            background-color: $gray;
            font-size: 24px;
            border-radius: $default-border-radius;
            padding: 10px 10px 5px;
            text-align: center;
            text-decoration: none;
            color: $white;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .cta-wrapper {
    text-align: center;
    margin: 75px 0px;
    .cta {
      display: inline-block;
      font-size: 25px;
      color: $white;
      padding: 10px 15px 5px;
      text-align: center;
      border-radius: $default-border-radius;
      background-color: $gray;
      text-decoration: none;
      max-width: 30%;
    }
  }
  .statements {
    background-image: url('../../assets/image/home/ship.png');
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-size: 200px auto;
    .custom-headline {
      margin-bottom: 100px;
    }
  }
  .custom-overflow-headline {
    margin: 50px;
    font-size: $big-size;
    text-align: center;
  }
  .overlap-section {
    background-size: auto 100%;
    height: 600px;
    background-repeat: no-repeat;
    position: relative;
    padding: 60px 0px;
    margin-bottom: 100px;
    .text {
      position: absolute;
      top: 50px;
      width: 50%;
    }
    .subheadline {
      font-size: $big-size;
      margin-top: 75px;
      font-weight: bold;
      font-family: 'boldfont';
    }
    .cta-wrapper {
      position: absolute;
      bottom: -50px;
      left: 0px;
      width: 100%;
      margin: 0px;
    }
    .image-on-mobile-wrapper {
      display: none;
      margin-top: 50px;
      height: auto;
      img {
        width: 100%;
      }
    }
    &.left {
      background-image: url('../../assets/image/home/bg-overlap-1.png');
      background-position: right;
    }
    &.right {
      background-image: url('../../assets/image/home/bg-overlap-2.png');
      background-position: left;
      .text {
        top: calc(50% - 50px);
        text-align: right;
        right: 0px;
      }
    }
  }
  .portfolio {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    align-items: center;
    margin: 50px 0px;
    .item {
      min-width: 20%;
      flex: 1 1 0px;
      text-align: center;
      img {
        max-height: 75px;
        max-width: 150px;
      }
    }
  }
  @media (max-width: $breakpoint) {
    .hero {
      height: 500px;
      .main-logo {
        text-align: center;
        margin-top: -100px;
        img {
          height: auto;
          width: 65%;
        }
      }
      .trusted {
        position: absolute;
        bottom: 10px;
        text-align: center;
        width: 100%;
        .label {
          margin-bottom: 5px;
          font-size: 15px;
        }
        .logos {
          display: flex;
          width: auto;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
          flex-wrap: wrap;
          .logo {
            padding: 0px 0px;
            width: 30%;
            img {
              max-height: 50px;
              max-width: 100px;
            }
          }
        }
      }
    }
    .section-sympol-wrapper {
      margin: 50px;
      margin-top: 0px;
    }
    .split {
      display: block;
      .item {
        .text {
          font-size: 24px;
        }
        &.img {
          text-align: center;
          img {
            max-height: auto;
            width: 100%;
            margin-top: 25px;
          }
        }
        .branches {
          .branch {
            font-size: $regular-size;
          }
        }
        .selected-branch {
          margin-top: 25px;
          .thumbnail {
            img {
              width: 100%;
            }
          }
          .characteristics {
            flex-wrap: wrap;
            margin-top: 15px;
            .char {
              flex: content;
              min-width: 40%;
              font-size: 18px;
              padding: 5px 20px 3px 20px;
            }
          }
        }
      }
    }
    .cta-wrapper {
      .cta {
        font-size: 24px;
        max-width: 100%;
      }
    }
    .section-sympol-wrapper {
      text-align: center;
      margin: 75px 0px;
      img {
        height: auto;
        max-height: 75px;
        max-width: 100%;
      }
    }
    .custom-overflow-headline {
      font-size: $regular-size;
      font-weight: bold;
      font-family: 'boldfont';
    }
    .overlap-section {
      height: auto;
      position: relative;
      padding: 0px 0px;
      margin-bottom: 100px;
      .image-on-mobile-wrapper {
        display: block;
      }
      .text {
        position: relative;
        width: 100%;
      }
      .subheadline {
        font-size: $regular-size;
      }
      .cta-wrapper {
        position: relative;
        margin-top: 50px;
      }
      &.left {
        background-image: none;
      }
      &.right {
        background-image: none;
        .text {
          text-align: center;
        }
      }
    }
    .portfolio {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      align-items: center;
      margin: 50px 0px;
      .item {
        min-width: 40%;
        flex: 1 1 0px;
        text-align: center;
        img {
          max-height: 75px;
          max-width: 150px;
        }
      }
    }
  }
}
