@import "../../../variables.scss";

.blackpaper-item {
  display: flex;
  gap: 40px;
  align-items: center;
  margin-top: 40px;
  z-index: 0;
  .thumbnail-wrapper {
    flex-wrap: wrap;
    height: 300px;
    width: 275px;
    background-color: $black;
    background-image: url("../../../assets/image/blackpaper/blackpaper-item-background.png");
    background-repeat: no-repeat;
    background-size: 200px 250px;
    background-position: center center;
    border-radius: $default-border-radius;
    position: relative;
    .download-overlay {
      transition: 0.2s ease-in-out;
      background-color: $black;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      padding-top: 45%;
      border-radius: 30px;
      text-align: center;
      &:hover {
        opacity: 0.9;
      }
    }
    .content {
      position: absolute;
      top: 100px;
      left: 50px;
      margin-bottom: 0px;
      width: 175px;
      .headline {
        font-size: 12px;
        font-weight: 500;
        line-height: 13px;
        width: 100%;
      }
      .text {
        width: 100%;
        font-size: 5px;
      }
    }
    .index {
      text-align: left;
      position: absolute;
      bottom: 30px;
      left: 50%;
      margin-left: -12px;
      margin-bottom: -2px;
      font-size: 7px;
      background-color: black;
      padding: 3px 2px 1px 2px;
      border-radius: 3px;
    }
  }
  .content {
    .headline {
      font-size: $big-size;
    }
    .text {
      font-size: $regular-size;
    }
  }
  @media (max-width: $breakpoint) {
    display: block;
    .thumbnail-wrapper {
      margin: 50px auto;
    }
    .content {
      .headline {
        font-size: 30px;
      }
      .text {
        font-size: 20px;
      }
    }
  }
}
