@import "../../variables.scss";

#imprint {
  padding-bottom: 50px;
  .start {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: $white;
  }
  .text {
    font-size: 20px;
    color: $white;
    margin: 50px 0px;
    .boldfont {
      font-family: "boldfont";
      font-weight: bold;
    }
    .headline {
      font-size: $big-size;
      font-family: "boldfont";
      font-weight: bold;
    }
    .subheadline {
      font-size: $big-size;
    }
  }
}
