@import '../../../variables.scss';

.what-we-do-item-list {
  margin: 50px 0px;
  display: flex;
  gap: 50px;
  .item {
    flex: 1 1 0px;
    min-width: 20%;
    text-align: center;
    .img {
      img {
        max-height: 75px;
        max-width: 75px;
      }
    }
    .headline {
      font-weight: bold;
      font-family: 'boldfont';
      font-size: 27px;
      text-align: center;
      margin: 25px 0px;
    }
    .description {
      font-size: 18px;
      min-height: 250px;
    }
  }
  @media (max-width: $breakpoint) {
    display: block;
    .item {
      margin-bottom: 50px;
      .headline {
        font-size: 30px;
        margin: 15px 0px;
      }
      .description {
        min-height: auto;
      }
    }
  }
}
