@import "../../../../variables.scss";

.branch-hex-elem {
  display: flex;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  align-items: center;
  flex: 1 1 0px;
  max-height: 200px;
  min-width: 150px;


  &.odd {
    margin-top: 100px;
  }
  @media (max-width: $breakpoint) {
    max-height: 150px;
    min-width: auto;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    &.odd {
      margin-top: 0px;
    }
    .text {
      margin-left: 50px;
      font-size: 24px;
    }
  }
}
