@import "../../variables.scss";

#contact {
  .wheel-wrapper {
    text-align: center;
    img {
      height: 75px;
      margin-top: 25px;
      margin-bottom: 75px;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    margin-top: 75px;
    margin: 75px 50px 0px;
    .bulletpoint-list {
      width: 60%;
      .bulletpoint {
        margin: 30px 0px;
        display: flex;
        align-items: center;
        gap: 30px;
        .image {
          img {
            height: 125px;
          }
        }
        a {
          text-decoration: none;
        }
        .text {
          color: $white;
          font-size: $big-size;
          font-weight: bold;
        }
      }
    }
    .ship {
      width: 40%;
      text-align: center;
      img {
        width: 300px;
      }
    }
  }
  .logo-wrapper {
    text-align: center;
    img {
      height: 200px;
      margin: 75px 0px;
    }
  }
  .social {
    margin: 50px 0px;
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    a {
      flex: 1 1 0px;
      max-width: 100px;
      text-align: center;
      img {
        width: 75px;
        height: auto;
      }
    }
  }
  @media (max-width: $breakpoint) {
    .flex {
      display: block;
      width: 100%;
      margin: 75px 15px 0px;
      .bulletpoint-list {
        width: 100%;
        .bulletpoint {
          .image {
            img {
              height: 50px;
            }
          }
          .text {
            font-size: $regular-size;
          }
        }
      }
      .ship {
        width: 100%;
        img {
          height: 250px;
          width: auto;
        }
      }
    }
    .logo-wrapper {
      img {
        height: 150px;
      }
    }
    .social {
      a {
        min-width: 40%;
        img {
        }
      }
    }
  }
}
