@import '../../variables.scss';

#whatwedo {
  .keywords {
    display: flex;
    gap: 30px;
    margin-bottom: 75px;
    .keyword {
      flex: 1 1 0px;
      background-color: $gray;
      text-align: center;
      font-size: 28px;
      line-height: 28px;
      padding: 10px 0px;
      border-radius: $default-border-radius;
    }
  }
  .headline-content {
    margin-top: 50px;
  }
  .hex-items {
    margin: 50px 0px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
  }
  .anchor-icon {
    text-align: center;
    margin: 50px 0px;
    img {
      height: 75px;
    }
  }
  .text {
    font-size: 30px;
    &.first {
      margin: 20px 0px 50px;
    }
  }
  .strategy-cta-wrapper {
    text-align: center;
    margin: 75px 0px;
    .strategy-cta {
      display: inline-block;
      font-size: 25px;
      color: $white;
      padding: 10px 15px 5px;
      text-align: center;
      border-radius: $default-border-radius;
      background-color: $gray;
      text-decoration: none;
      max-width: 30%;
    }
  }
  .custom-headline-content {
    margin-top: 50px;
  }
  @media (max-width: $breakpoint) {
    .hex-items {
      // display: block;
      flex-wrap: wrap;
      gap: 25px;
    }
    .text {
      font-size: 24px;
      line-height: 30px;
    }
    .strategy-cta-wrapper {
      .strategy-cta {
        width: 100%;
        max-width: 100%;
        font-size: 24px;
        line-height: 28px;
        padding: 10px 15px 5px;
      }
    }
  }
}
