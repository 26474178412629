@import "../../../variables.scss";

.custom-headline {
  color: $white;
  font-family: "boldfont";
  font-size: $headline-size;
  font-weight: bold;
  line-height: $headline-size + 15px;
  width: 90%;
  &.center {
    text-align: center;
    width: 100%;
  }
  @media (max-width: $breakpoint) {
    font-size: 30px;
    line-height: 35px;
    width: 100%;
  }
}
