@import '../../variables.scss';

#anchorstrategy {
  .headline-content {
    margin-top: 50px;
  }
  .hex-items {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
  }
  .book-meeting-cta-wrapper {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
    .book-meeting-cta {
      display: inline-block;
      background-color: $gray;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
      text-decoration: none;
      color: $white;
      width: 25%;
      padding: 10px 10px 5px;
      border-radius: $default-border-radius;
    }
  }
  .graph-section {
    margin: 50px 0px;
    display: flex;
    gap: 50px;
    align-items: center;
    .bulletpoints-and-quotes {
      width: 55%;
      .bulletpoints {
        .bulletpoint {
          font-family: 'boldfont';
          font-weight: bold;
          font-size: 22px;
          line-height: 28px;
        }
      }
      .quotes {
        .quote {
          margin-top: 30px;
          font-size: 22px;
          line-height: 28px;
        }
      }
    }
    .graph {
      width: 40%;
      img {
        max-width: 100%;
      }
    }
  }
  .text-blocks {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 40px 0px;
    .block {
      flex: 1 1 0px;
      min-width: 40%;
      .headline {
        font-family: 'boldfont';
        font-weight: bold;
      }
    }
  }
  .multi-hexagon-graphic,
  .timeline {
    margin: 50px 0px;
    text-align: center;
    img {
      height: 500px;
    }
  }
  .timeline {
    margin-bottom: 100px;
    img {
      height: auto;
      max-width: 90%;
    }
  }
  @media (max-width: $breakpoint) {
    .hex-items {
      margin-top: 50px;
      gap: 15px;
      flex-wrap: wrap;
    }
    .book-meeting-cta-wrapper {
      .book-meeting-cta {
        width: 100%;
      }
    }
    .graph-section {
      display: block;
      gap: 50px;
      .bulletpoints-and-quotes {
        width: 100%;
        .bulletpoints {
          .bulletpoint {
            font-family: 'boldfont';
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 10px;
          }
        }
        .quotes {
          .quote {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
      .graph {
        width: 100%;
        margin-top: 50px;
        img {
          max-width: 100%;
        }
      }
    }
    .text-blocks {
      display: block;
      .block {
        margin-bottom: 25px;
      }
    }
    .multi-hexagon-graphic,
    .timeline {
      margin: 50px 0px;
      text-align: center;
      img {
        height: auto;
        width: 100%;
      }
    }
  }
}
