@import "../../../variables.scss";

#header {
  position: absolute;
  &.fixed {
    position: fixed;
  }
  width: 100vw;
  display: flex;
  top: 0px;
  padding: 30px 50px;
  align-items: center;
  z-index: 998;
  .logo,
  .toggle {
    z-index: 998;
    width: 50%;
  }
  .logo {
    img {
      height: 30px;
    }
  }
  .toggle {
    z-index: 1000;
    text-align: right;
    img {
      height: 50px;
      cursor: pointer;
    }
  }
  .navigation {
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    background-color: $bg;
    left: 0px;
    top: 0px;
    padding: 30px 50px;
    padding-left: 20%;
    .item {
      font-size: 40px;
      margin-top: 10px;
      &.small {
        font-size: 30px;
      }
      a {
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
    .social-icons {
      margin-top: 20px;
      a {
        padding: 0px 10px;
        &:first-child {
          padding-left: 0px;
        }
        img {
          max-height: 40px;
          max-width: 40px;
        }
      }
    }
  }
  @media (max-width: $breakpoint) {
    padding: 15px;
    .toggle {
      img {
        height: 30px;
      }
    }
    .logo {
      img {
        display: none;
        height: 20px;
      }
    }
    .navigation {
      padding: 75px 15px;
      .item {
        font-size: $regular-size;
        &.small {
        font-size: $small-size;
      }
      }
      .social-icons {
        a {
          img {
            max-height: 35px;
            max-width: 35px;
          }
        }
      }
    }
  }
}
