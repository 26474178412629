@import '../../variables.scss';

#anchorworkshop {
  .key-numbers {
    margin-top: 50px;
    display: flex;
    gap: 50px;
  }
  .hex-items {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
  }
  .book-meeting-cta-wrapper {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
    .book-meeting-cta {
      display: inline-block;
      background-color: $gray;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
      text-decoration: none;
      color: $white;
      width: 25%;
      padding: 10px 10px 2px;
      border-radius: $default-border-radius;
    }
  }
  .headline-content {
    margin: 50px 0px;
  }
  .bulletpoints {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    margin-bottom: 100px;
  }
  @media (max-width: $breakpoint) {
    .hex-items {
        margin-top: 50px;
        gap: 15px;
        flex-wrap: wrap;
    }
    .book-meeting-cta-wrapper {
      .book-meeting-cta {
        width: 100%;
      }
    }
    .key-numbers {
      display: block;
    }
    .bulletpoints {
      display: block;
    }
  }
}
