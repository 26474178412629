@import '../../../variables.scss';

.hexElem {
  height: 200px;
  &.odd {
    margin-top: 100px;
  }
  @media (max-width: $breakpoint) {
    height: 150px;
    width: calc(50% - 15px);
    &.odd {
      margin-top: 0px;
    }
  }
}