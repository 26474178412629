@import '../../variables.scss';

#whoweare {
  .headline-content {
    margin-top: 50px;
  }
  .separation-image {
    text-align: center;
    margin: 75px 0px;
    img {
      height: 350px;
    }
  }
  .statements {
    background-image: url('../../assets/image/who-we-are/anchor.png');
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-size: 200px auto;
  }
  .wheel-wrapper {
    text-align: center;
    img {
      height: 75px;
      margin: 50px 0px;
    }
  }
  .subheadline {
    margin-top: 20px;
    font-size: 30px;
    .bold {
      font-weight: bold;
      font-family: 'boldfont';
    }
  }
  .hex-items {
    margin: 75px 0px;
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
  }
  .meeting-cta-wrapper {
    text-align: center;
    .meeting-cta {
      display: inline-block;
      background-color: $gray;
      border-radius: $default-border-radius;
      font-size: 25px;
      padding: 10px 20px 5px;
      margin-top: 75px;
      color: $white;
      text-decoration: none;
    }
  }
  @media (max-width: $breakpoint) {
    .separation-image {
      img {
        height: auto;
        width: 100%;
      }
    }
    .subheadline {
      font-size: 24px;
    }
    .meeting-cta-wrapper {
      .meeting-cta {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }
}
