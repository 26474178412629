@import "../../../variables.scss";

#branch-template {
  .keywords {
    display: flex;
    gap: 30px;
    margin-bottom: 75px;
    .keyword {
      flex: 1 1 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray;
      text-align: center;
      font-size: 24px;
      line-height: 28px;
      padding: 10px 20px;
      border-radius: $default-border-radius;
      text-transform: uppercase;
    }
  }
  .custom-headline {
    margin-top: 50px;
    text-transform: uppercase;
  }
  .subheadline {
    text-transform: uppercase;
  }
  .hex-items {
    margin: 50px 0px;
    display: flex;
    justify-content: left;
    gap: 50px;
    .hex-item-wrapper {
      width: 20%;
      text-align: left;
    }
  }
  .trusted-by {
    margin: 75px 0px;
    text-align: center;
    .label {
      background-color: $gray;
      border-radius: 5px;
      display: inline-block;
      padding: 5px 10px 1px 10px;
      font-size: 20px;
      font-weight: bold;
      font-family: "boldfont";
    }
    .brands {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 30px;
      margin: 50px 0px;
      .logo {
        text-align: center;
        flex: 1 1 0px;
        img {
          max-height: 150px;
          max-width: 150px;
        }
      }
    }
  }
  .anchor-icon {
    text-align: center;
    margin-top: 50px;
    img {
      height: 75px;
    }
  }
  .subheadline {
    font-size: $big-size;
  }
  .bulletpoints {
    margin-top: 30px;
    padding-top: 100px;
    margin-bottom: 200px;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;
    .bulletpoint {
      font-family: "boldfont";
      font-weight: bold;
      font-size: 30px;
      .text {
        background-color: $white;
        display: inline-block;
        color: $black;
        padding: 3px;
        line-height: 30px;
      }
    }
  }
  .bulletpoint-image-mobile {
    display: none;
    margin-top: 15px;
    img {
      width: 100%;
    }
  }
  .go-to-anchorstrategy-cta-wrapper {
    margin-top: 50px;
    margin-bottom: 30px;
    .go-to-anchorstrategy {
      display: inline-block;
      background-color: $gray;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
      text-decoration: none;
      color: $white;
      width: 25%;
      padding: 10px 10px 3px 10px;
      border-radius: $default-border-radius;
    }
  }
  @media (max-width: $breakpoint) {
    .keywords {
      flex-wrap: wrap;
      gap: 15px;
      display: none;
      .keyword {
        padding: 5px 20px 2px 20px;
        font-size: 18px;
      }
    }
    .hex-items {
      flex-wrap: wrap;
      gap: 15px;
      .hex-item-wrapper {
        width: 100%;
      }
    }
    .trusted-by {
      .brands {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
      }
    }
    .anchor-icon {
      margin-bottom: 50px;
    }
    .subheadline {
      font-size: 24px;
    }
    .bulletpoints {
      background-image: none !important;
      padding-top: 0px;
      .bulletpoint {
        font-size: 20px;
        margin-top: 20px;
      }
    }
    .bulletpoint-image-mobile {
      display: block;
    }
    .go-to-anchorstrategy-cta-wrapper {
      .go-to-anchorstrategy {
        width: 100%;
      }
    }
  }
}
