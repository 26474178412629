@import "../../../variables.scss";

#footer {
  .split {
    margin-top: 50px;
    display: flex;
    .contact {
      flex: 1 1 0px;
      * {
        color: $gray;
        text-decoration: none;
      }
    }
    .copyright {
      flex: 1 1 0px;
      font-family: "lightfont";
      text-align: right;
      .bold {
        font-family: "boldfont";
        font-weight: bold;
      }
    }
  }
  @media (max-width: $breakpoint) {
    .split {
      display: block;
      .copyright {
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}
